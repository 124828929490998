import jsSHA from "jssha"; //See: https://caligatio.github.io/jsSHA/

/**
 * Protect a password by creating a SHA-512 hash, before it is send to the server
 * @param salt some unmodifyable, user specific data, that is used as first part of the hash, to make dictionary attacks more difficult
 * @param password the password to protect
 * @returns a 128 character hexadecimal number representing the 512 bit hash code
 */
export function calculatePasswordHash(salt: string, password: string): string {
  const hashFunction = new jsSHA("SHA-512", "TEXT");
  if (salt) {
    hashFunction.update(salt);
  }
  if (password) {
    hashFunction.update(password);
  }
  return hashFunction.getHash("HEX");
}
