import { useEffect, useState, useContext } from "react";
import { firstBy } from "thenby";
import { Row, Col, DatePicker, Button, Spin, Table } from "antd";
import { localizer } from "di-common";
import financeDao from "../../dao/FinanceDao";
import StateContext from "../../StateContext";

/**
 * This component displays a period selector and when clicked on the 'select' button,
 * the vat entries that match the selected period are retrieved and displayed.
 */
function VatSummaryPanel() {
  const { RangePicker } = DatePicker;
  const [period, setPeriod] = useState([]);
  const [results, setResults] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [clickCount, setClickCount] = useState(0);
  const {
    state: { currentUser }
  } = useContext(StateContext);

const tableColumns = [
  {
    title: localizer.resolve("finance.vatTable.description"),
    dataIndex: "description",
    key: "description"
  },
  {
    title: localizer.resolve("finance.vatTable.paydate"),
    dataIndex: "dateReceived",
    key: "dateReceived"
  },
  {
    title: localizer.resolve("finance.vatTable.country"),
    dataIndex: "iso3166_1_countryCode",
    key: "iso3166_1_countryCode"
  },
  {
    title: localizer.resolve("finance.vatTable.state"),
    dataIndex: "stateName",
    key: "stateName"
  },
  {
    title: localizer.resolve("finance.vatTable.vatAmount"),
    dataIndex: "vatAmountInEur",
    key: "vatAmountInEur"
  },
  {
    title: localizer.resolve("finance.vatTable.payedAmount"),
    dataIndex: "payedAmountInEur",
    key: "payedAmountInEur"
  }
];

  useEffect(() => {
    if (period[0] != null && period[1] != null) {
      const startDate = period[0].format("YYYY-MM-DD");
      const endDate = period[1].format("YYYY-MM-DD");
      setLoading(true);
      financeDao.getVatEntries(
        currentUser,
        startDate,
        endDate,
        response => {
          setLoading(false);
          console.log(response);
          setResults(prepareForDisplay(addTotalsToVatEntries(response)));
        },
        error => {
          setLoading(false);
          console.error(error);
          //TODO: show error feedback to user
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clickCount]);

  function renderContent() {
    if (isLoading) {
      return <Spin size="large" />;
    }

    if (period == null || period[0] == null || period[1] == null) {
      return <p>{localizer.resolve("finance.selectMessage")}</p>;
    }
    if (results != null) {
      if (results.length === 0) {
        return <p>{localizer.resolve("finance.noResultsMessage")}</p>;
      } else {
        return (
          <Table
            dataSource={results}
            columns={tableColumns}
            pagination={false}
            locale={localizer.getCurrentLocale()}
          />
        );
      }
    }
  }

  return (
    <>
      <Row wrap={false}>
        <Col flex={1}>
          <span>{localizer.resolve("finance.selectCaption")}</span>
          <RangePicker
            value={period}
            inputReadOnly={isLoading}
            onChange={period => {
              setResults(null);
              setPeriod(period);
            }}
          />
          <Button
            type="primary"
            onClick={() => setClickCount(clickCount + 1)}
            disabled={isLoading || period[0] == null || period[1] == null}
          >
            {localizer.resolve("finance.buttonCaption")}
          </Button>
        </Col>
      </Row>
      <Row wrap={false} hidden={period === null}>
        <Col flex={1}>{renderContent()}</Col>
      </Row>
    </>
  );
}

function prepareForDisplay(vatEntries) {
  if (vatEntries === null || vatEntries.length === 0) {
    return [];
  }

  function createDescription(item) {
    if (item.subtotal === true) {
      return localizer.resolve("finance.vatTable.subtotal");
    } else if (item.grandtotal === true) {
      return localizer.resolve("finance.vatTable.grandtotal");
    } else {
      return item.providerPaymentId;
    }
  }

  const displayableEntries = [];
  vatEntries.forEach(item =>
    displayableEntries.push({
      ...item,
      key: item.providerPaymentId,
      description: createDescription(item)
    })
  );
  return displayableEntries;
}

export const vatEntryComparator = firstBy("iso3166_1_countryCode")
  .thenBy("stateName")
  .thenBy("dateReceived");

/**
 * Order the entries by vat regime (they should already be ordered this way,
 * but we don't want to make assumptions) and after each switch of vat regime,
 * add a total line, summing up the vat amounts and revenue per regime. End
 * the report with a total amounts for the entire overview.
 */
export function addTotalsToVatEntries(vatEntries) {
  function extractVatRegime(entry) {
    return {
      country: entry.iso3166_1_countryCode,
      state: entry.stateName
    };
  }
  if (vatEntries && Array.isArray(vatEntries)) {
    vatEntries = vatEntries.filter(
      item =>
        //Only handle objects that have the mandatory VAT entry properties
        Object.prototype.toString.call(item) === "[object Object]" &&
        item.iso3166_1_countryCode &&
        item.stateName !== undefined &&
        item.payedAmountInEur
    );
    const results = [];
    if (vatEntries.length > 0) {
      vatEntries.sort(vatEntryComparator);

      let totalRevenue = 0;
      let totalVat = 0;
      let regimeRevenue = 0;
      let regimeVat = 0;
      let current = extractVatRegime(vatEntries[0]);
      vatEntries.forEach((item, index) => {
        if (!Number.isFinite(item.payedAmountInEur)) {
          item.payedAmountInEur = 0;
        }
        if (!Number.isFinite(item.vatAmountInEur)) {
          item.vatAmountInEur = 0;
        }
        totalRevenue += item.payedAmountInEur;
        totalVat += item.vatAmountInEur;
        regimeRevenue += item.payedAmountInEur;
        regimeVat += item.vatAmountInEur;

        item.vatAmountInEur = item.vatAmountInEur.toFixed(2);
        item.payedAmountInEur = item.payedAmountInEur.toFixed(2);
        results.push(item);

        //Peek ahead in the next object in the array if there is a VAT regime change
        let regime =
          index < vatEntries.length - 1
            ? extractVatRegime(vatEntries[index + 1])
            : null;
        if (
          regime === null ||
          regime.country !== current.country ||
          regime.state !== current.state
        ) {
          //change of regime -- add subtotal entry to results array
          results.push({
            subtotal: true,
            iso3166_1_countryCode: current.country,
            stateName: current.state,
            vatAmountInEur: regimeVat.toFixed(2),
            payedAmountInEur: regimeRevenue.toFixed(2)
          });

          //reset counters
          regimeVat = 0;
          regimeRevenue = 0;
        }
        current = regime;
      });
      //Add grand total revenue entry to results
      results.push({
        grandtotal: true,
        vatAmountInEur: totalVat.toFixed(2),
        payedAmountInEur: totalRevenue.toFixed(2)
      });
    }
    return results;
  }
  return null;
}

export default VatSummaryPanel;
