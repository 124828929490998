import Axios from "axios";

class UserDao {
  getUsers(currentUser, responseCallback, errorCallback) {
    Axios.get("/api/private/users", {
      headers: { Authorization: "Bearer " + currentUser.token }
    }).then(response => responseCallback(response.data), errorCallback);
  }

  getUserInfo(userId, currentUser, responseCallback, errorCallback) {
    Axios.get(`/api/private/user/${userId}`, {
      headers: { Authorization: "Bearer " + currentUser.token }
    }).then(response => responseCallback(response.data), errorCallback);
  }

  getActivityHighlights(userId, currentUser, responseCallback, errorCallback) {
    Axios.get(`/api/private/activity-highlights/${userId}`, {
      headers: { Authorization: "Bearer " + currentUser.token }
    }).then(response => responseCallback(response.data), errorCallback);
  }
}

const userDao = new UserDao();
export default userDao;
