/** Signature of a logging function */
type LogFn = (message?: any, ...optionalParams: any[]) => void;

/** Basic logger interface */
export type Logger = {
  debug: LogFn;
  info: LogFn;
  warn: LogFn;
  error: LogFn;
};

/**
 * The supported log levels enumeration. We start numbering at 1, so we don't get 'Falsy' (0 = falsy),
 * when we retrieve the loglevel as a value from a Map
 */
export enum LogLevel {
  DEBUG = 1,
  INFO,
  WARN,
  ERROR
}

const NO_OP: LogFn = (message?: any, ...optionalParams: any[]) => {};

/**
 * Logger which outputs to the browser console. It's purpose is to provide a logging mechanism
 * for development that can be 'turned off' in production.
 * Inspired by https://www.meticulous.ai/blog/getting-started-with-react-logging
 */
export class ConsoleLogger implements Logger {
  /**
   * Logging is specifically meant for development process. If we want to log to production, we should use
   * console.log instead of the ConsoleLogger
   */
  private static disableLogging = process.env.NODE_ENV === "production";

  /** The default log level, meaning the level used when loggerName is not configured explicitely */
  private static rootLogLevel = LogLevel.INFO;

  public readonly debug: LogFn;
  public readonly info: LogFn;
  public readonly warn: LogFn;
  public readonly error: LogFn;

  /**
   * Create a logger instance for use in a javascript files
   * @param loggerName The name of the logger, which will be part of the output to the console.
   *        Usually the name of the javascript file or component.
   * @param outputLevel The minimum LogLevel that a message must be logged on, in order to show up in the console.
   *        When not provided, it will be set to LogLevel.INFO
   */
  constructor(loggerName: string, outputLevel?: LogLevel) {
    const effectiveLevel = outputLevel || ConsoleLogger.rootLogLevel;
    const enabled = !ConsoleLogger.disableLogging;

    this.debug = enabled && effectiveLevel <= LogLevel.DEBUG ? console.debug.bind(console) : NO_OP;
    this.info = enabled && effectiveLevel <= LogLevel.INFO ? console.info.bind(console) : NO_OP;
    this.warn = enabled && effectiveLevel <= LogLevel.WARN ? console.warn.bind(console) : NO_OP;
    this.error = enabled ? console.error.bind(console) : NO_OP;
  }
}
