import { useContext, useEffect, useState } from "react";
import { Descriptions, Spin } from "antd";
import { localizer } from "di-common";
import userDao from "../../dao/UserDao";
import StateContext from "../../StateContext";

function ActivityHighlightsWidget({ userId }) {
  const [isLoading, setLoading] = useState(true);
  const [activityHighlights, setActivityHighlights] = useState({});
  const {
    state: { currentUser }
  } = useContext(StateContext);

  useEffect(() => {
    userDao.getActivityHighlights(
      userId,
      currentUser,
      response => {
        setLoading(false);
        console.log("WriteHightlights:", response);
        setActivityHighlights(response);
      },
      error => {
        setLoading(false);
        console.dir(error);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  if (isLoading) {
    return <Spin size="small" />;
  }

  return (
    <>
      <Descriptions
        title={localizer.resolve("activityHighlightsWidget.title")}
        column={2}
        bordered={true}
        size="small"
      >
        <Descriptions.Item
          label={localizer.resolve("activityHighlightsWidget.fragmentCount")}
        >
          {activityHighlights.fragmentCount}
        </Descriptions.Item>
        <Descriptions.Item
          label={localizer.resolve(
            "activityHighlightsWidget.fragmentWordCount"
          )}
        >
          {activityHighlights.fragmentWordCount}
        </Descriptions.Item>
      </Descriptions>

      <Descriptions column={3} bordered={true} size="small">
        <Descriptions.Item
          label={localizer.resolve("activityHighlightsWidget.ideaCount")}
        >
          {activityHighlights.ideaCount}
        </Descriptions.Item>
        <Descriptions.Item
          label={localizer.resolve("activityHighlightsWidget.projectCount")}
        >
          {activityHighlights.projectCount}
        </Descriptions.Item>
        <Descriptions.Item
          label={localizer.resolve("activityHighlightsWidget.projectWordCount")}
        >
          {activityHighlights.projectWordCount}
        </Descriptions.Item>
      </Descriptions>

      <Descriptions column={5} bordered={true} size="small" layout="vertical">
        <Descriptions.Item
          label={localizer.resolve("activityHighlightsWidget.characterCount")}
        >
          {activityHighlights.characterCount}
        </Descriptions.Item>
        <Descriptions.Item
          label={localizer.resolve("activityHighlightsWidget.groupCount")}
        >
          {activityHighlights.groupCount}
        </Descriptions.Item>
        <Descriptions.Item
          label={localizer.resolve("activityHighlightsWidget.locationCount")}
        >
          {activityHighlights.locationCount}
        </Descriptions.Item>
        <Descriptions.Item
          label={localizer.resolve("activityHighlightsWidget.eventCount")}
        >
          {activityHighlights.eventCount}
        </Descriptions.Item>
        <Descriptions.Item
          label={localizer.resolve("activityHighlightsWidget.thingCount")}
        >
          {activityHighlights.thingCount}
        </Descriptions.Item>
      </Descriptions>

      <Descriptions column={1} bordered={true} size="small">
        <Descriptions.Item
          label={localizer.resolve("activityHighlightsWidget.resourceCount")}
        >
          {activityHighlights.resourceCount}
        </Descriptions.Item>
      </Descriptions>
    </>
  );
}

export default ActivityHighlightsWidget;
