import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Spin, Table } from "antd";
import { localizer } from "di-common";
import userDao from "../../dao/UserDao";
import StateContext from "../../StateContext";

function UserOverviewPanel() {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const {
    state: { currentUser }
  } = useContext(StateContext);

  useEffect(() => {
    userDao.getUsers(
      currentUser,
      response => {
        setLoading(false);
        setData(response);
      },
      error => {
        setLoading(false);
        console.dir(error);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function dateSorter(date1, date2) {
    //missing values come after a value, so: nulls at the bottom when sorting desccending
    if (date1 === date2) {
      return 0;
    }
    if (!date1) {
      return -1;
    }
    if (!date2) {
      return 1;
    }
    if (date1 < date2) {
      return -1;
    }
    return 1;
  }

  const columns = [
    {
      title: localizer.resolve("user.email"),
      dataIndex: "email",
      key: "email"
    },
    {
      title: localizer.resolve("user.displayName"),
      dataIndex: "displayName",
      key: "displayName"
    },
    {
      title: localizer.resolve("user.dateCreated"),
      dataIndex: "dateCreated",
      key: "dateCreated",
      sorter: (a, b) => dateSorter(a.dateCreated, b.dateCreated),
      sortDirections: ["descend", "ascend"]
    },
    {
      title: localizer.resolve("user.dateActivated"),
      dataIndex: "dateActivated",
      key: "dateActivated",
      sorter: (a, b) => dateSorter(a.dateActivated, b.dateActivated),
      sortDirections: ["descend", "ascend"]
    },
    {
      title: localizer.resolve("user.lastLogin"),
      dataIndex: "lastLoggedIn",
      key: "lastLoggedIn",
      sorter: (a, b) => dateSorter(a.lastLoggedIn, b.lastLoggedIn),
      sortDirections: ["descend", "ascend"]
    }
  ];

  if (isLoading === true) {
    return <Spin size="large" />;
  }

  return (
    <Table
      dataSource={data}
      columns={columns}
      size="small"
      style={{ cursor: "pointer" }}
      pagination={false}
      rowKey={record => record.id}
      onRow={(record, rowIndex) => {
        return {
          onDoubleClick: event => {
            navigate("/user/" + record.id);
          }
        };
      }}
    />
  );
}

export default UserOverviewPanel;
