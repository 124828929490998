import { useNavigate } from "react-router-dom";
import { Menu } from "antd";
import { PoweroffOutlined } from "@ant-design/icons";
import RemoveOrphansMenuItems from "./actions/RemoveOrphansMenuItem";
import { localizer } from "di-common";

function ApplicationMenu({ dispatch }) {
  const navigate = useNavigate();
  return (
    <Menu mode="vertical" triggerSubMenuAction="click">
      <Menu.SubMenu key="s1" title={localizer.resolve("menu.files")}>
        <RemoveOrphansMenuItems />
      </Menu.SubMenu>
      <Menu.SubMenu key="s2" title={localizer.resolve("menu.users")}>
        <Menu.Item key="s2i1" onClick={() => navigate("/users")}>
          {localizer.resolve("menu.userOverview")}
        </Menu.Item>
      </Menu.SubMenu>
      <Menu.SubMenu key="s3" title={localizer.resolve("menu.finance")}>
        <Menu.Item key="s3i1" onClick={() => navigate("/vat-summary")}>
          {localizer.resolve("menu.vatSummary")}
        </Menu.Item>
      </Menu.SubMenu>
      <Menu.Item
        key="99"
        icon={<PoweroffOutlined />}
        onClick={() => {
          navigate("/");
          dispatch({ type: "logout" });
        }}
      >
        {localizer.resolve("menu.logout")}
      </Menu.Item>
    </Menu>
  );
}

export default ApplicationMenu;
