import Axios from "axios";

class ResourceDao {
  removeOrphans(currentUser, responseCallback, errorCallback) {
    Axios.delete("/api/private/recources/orphans", {
      headers: { Authorization: "Bearer " + currentUser.token }
    }).then(response => responseCallback(response.data), errorCallback);
  }
}

const resourceDao = new ResourceDao();
export default resourceDao;
