import { useContext, useEffect, useState } from "react";
import { Descriptions, Spin } from "antd";
import { localizer } from "di-common";
import userDao from "../../dao/UserDao";
import StateContext from "../../StateContext";

function UserInfoWidget({ userId }) {
  const [isLoading, setLoading] = useState(true);
  const [userInfo, setUserInfo] = useState({});
  const {
    state: { currentUser }
  } = useContext(StateContext);

  useEffect(() => {
    userDao.getUserInfo(
      userId,
      currentUser,
      response => {
        setLoading(false);
        console.log("Userinfo:", response);
        setUserInfo(response);
      },
      error => {
        setLoading(false);
        console.dir(error);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  if (isLoading) {
    return <Spin size="small" />;
  }

  return (
    <Descriptions
      title={localizer.resolve("userInfoWidget.title")}
      column={1}
      bordered={true}
      size="small"
    >
      <Descriptions.Item label={localizer.resolve("user.id")}>
        {userInfo.id}
      </Descriptions.Item>
      <Descriptions.Item label={localizer.resolve("user.displayName")}>
        {userInfo.displayName}
      </Descriptions.Item>
      <Descriptions.Item label={localizer.resolve("user.email")}>
        {userInfo.email}
      </Descriptions.Item>
      <Descriptions.Item label={localizer.resolve("user.dateActivated")}>
        {userInfo.dateActivated}
      </Descriptions.Item>
      <Descriptions.Item label={localizer.resolve("user.lastLogin")}>
        {userInfo.lastLoggedIn}
      </Descriptions.Item>
      <Descriptions.Item label={localizer.resolve("user.countryCode")}>
        {userInfo.countryCode}
      </Descriptions.Item>
    </Descriptions>
  );
}

export default UserInfoWidget;
