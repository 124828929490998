import { useEffect, useState, useContext } from "react";
import { Menu, message } from "antd";
import { localizer } from "di-common";
import resourceDao from "../../../dao/ResourcesDao";
import StateContext from "../../../StateContext";

function RemoveOrphansMenuItem(props) {
  const [isLoading, setLoading] = useState(false);

  const {
    state: { currentUser }
  } = useContext(StateContext);

  function sendRequest() {
    setLoading(true);
    resourceDao.removeOrphans(currentUser, operationFinished, operationFailed);
  }

  function operationFinished(responseData) {
    setLoading(false);
    message.info(localizer.resolve("orphans.feedback.finished", responseData));
  }

  function operationFailed() {
    setLoading(false);
    message.error(localizer.resolve("orphans.feedback.failed"));
  }

  useEffect(() => {
    if (isLoading === true) {
      const handle = message.loading(
        localizer.resolve("orphans.dispatched"),
        0
      );
      return () => setTimeout(handle, 0);
    }
  }, [isLoading]);

  return (
    <Menu.Item {...props} key="1" onClick={sendRequest}>
      {localizer.resolve("menu.orphans")}
    </Menu.Item>
  );
}

export default RemoveOrphansMenuItem;
