import Axios from "axios";

class FinanceDao {
  getVatEntries(
    currentUser,
    periodStart,
    periodEnd,
    responseCallback,
    errorCallback
  ) {
    Axios.get(`/api/private/vat/${periodStart}/${periodEnd}`, {
      headers: { Authorization: "Bearer " + currentUser.token }
    }).then(response => responseCallback(response.data), errorCallback);
  }
}

const financeDao = new FinanceDao();
export default financeDao;
