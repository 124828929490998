import { useEffect } from "react";
import { createRoot } from "react-dom/client";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import { useImmerReducer } from "use-immer";
import { Layout } from "antd";
import { localizer, NotFound } from "di-common";
import { AppInfo, SessionConstants } from "./Constants";
import MainPanel from "./components/dashboard/MainPanel";
import ApplicationMenu from "./components/dashboard/ApplicationMenu";
import UserOverviewPanel from "./components/users/UserOverviewPanel";
import UserDetailPanel from "./components/users/UserDetailPanel";
import VatSummaryPanel from "./components/finance/VatSummaryPanel";
import Login from "./components/auth/Login";
import StateContext from "./StateContext";

function DigitalInkMgmt() {
  localizer.registerLocaleChangeListener("DigitalInkMgmt", onLocaleChanged);

  function mgmtReducer(draft, action) {
    switch (action.type) {
      case "login":
        draft.isLoggedIn = true;
        draft.currentUser = action.data;
        break;
      case "logout":
        draft.isLoggedIn = false;
        draft.currentUser = null;
        sessionStorage.clear();
        break;
      case "changeUiLocale":
        draft.renderLocale = action.value;
        break;
      default:
        break;
    }
  }

  const [state, dispatch] = useImmerReducer(mgmtReducer, {
    renderLocale: null,
    isLoggedIn: Boolean(sessionStorage.getItem(SessionConstants.USER_KEY)),
    currentUser: sessionStorage.getItem(SessionConstants.USER_KEY)
      ? JSON.parse(sessionStorage.getItem(SessionConstants.USER_KEY))
      : null
  });

  useEffect(() => {
    if (state.isLoggedIn) {
      sessionStorage.setItem(
        SessionConstants.USER_KEY,
        JSON.stringify(state.currentUser)
      );
    } else {
      sessionStorage.removeItem(SessionConstants.USER_KEY);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.isLoggedIn]);

  function onLocaleChanged(newUiLocale) {
    /* This is a callback function that is called by the localizer when translations have been loaded
     * for a newly selected ProjectLanguageFormItem, and thus the GUI can be re-rendered
     */
    if (
      newUiLocale &&
      typeof newUiLocale === "string" &&
      newUiLocale !== state.renderLocale
    ) {
      console.log(
        `Changing locale from ${state.renderLocale} to ${newUiLocale}`
      );
      dispatch({ type: "changeUiLocale", value: newUiLocale });
    }
  }

  const { Header, Content, Sider } = Layout;
  return (
    <StateContext.Provider value={{ state }}>
      <Router>
        {!state.isLoggedIn ? (
          <Login dispatch={dispatch} />
        ) : (
          <Layout style={{ height: "100vh" }}>
            <Header>
              <div className="branding">
                <span className="branding__name">{AppInfo.appName}</span>
                <span className="branding__version">{AppInfo.version}</span>
              </div>
            </Header>
            <Layout>
              <Sider>
                <ApplicationMenu dispatch={dispatch} />
              </Sider>
              <Content>
                <Routes>
                  <Route exact path="/" element={<MainPanel />} />
                  <Route exact path="/users" element={<UserOverviewPanel />} />
                  <Route path="/user/:userId" element={<UserDetailPanel />} />
                  <Route path="/vat-summary" element={<VatSummaryPanel />} />
                  <Route element={<NotFound />} />
                </Routes>
              </Content>
            </Layout>
          </Layout>
        )}
      </Router>
    </StateContext.Provider>
  );
}

const root = createRoot(document.getElementById("root"));
root.render(<DigitalInkMgmt />);
