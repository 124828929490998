import { useParams } from "react-router-dom";
import { Col, Row } from "antd";
import UserInfoWidget from "./UserInfoWidget";
import ActivityHighlightsWidget from "./ActivityHighlightsWidget";

function UserDetailPanel() {
  const { userId } = useParams();

  return (
    <Row wrap={false}>
      <Col flex={1}>
        <UserInfoWidget userId={userId} />
      </Col>
      <Col flex={1}>
        <ActivityHighlightsWidget userId={userId} />
      </Col>
    </Row>
  );
}

export default UserDetailPanel;
