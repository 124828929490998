import intl from "react-intl-universal";
import axios from "axios";
import { ConsoleLogger } from "../utils/ConsoleLogger";

export const UI_LOCALE_KEY = "uiLocale";

const fallbackLocale = "en";

export const SUPPORTED_LOCALES: Map<string, String> = new Map([
  ["en", "English"],
  ["nl", "Nederlands"]
]);

export type LocalizationService = {
  resolve: (key: string, options?: any) => string;
  getCurrentLocale(): string;
};

const logger = new ConsoleLogger("DefaultLocalizationService");

/* This class is responsible for resolving keys into localized text. It hides the
 * underlying implementation, so it is possible to change it without too much effort.
 * Current implementation: https://github.com/alibaba/react-intl-universal
 */
class DefaultLocalizationService implements LocalizationService {
  private languages: Map<string, string>;
  private currentLocale?: string = undefined;
  private localeChangeListeners: Map<string, Function>;

  constructor(locale: string) {
    this.languages = new Map<string, string>();
    this.setLocale(locale);
    this.localeChangeListeners = new Map<string, Function>();
  }

  async setLocale(locale = fallbackLocale) {
    if (typeof locale !== "string") {
      locale = fallbackLocale;
    }
    if (SUPPORTED_LOCALES.has(locale)) {
      if (this.currentLocale !== locale) {
        this.currentLocale = locale;
        await Promise.all([this.loadBundle(locale), this.loadLanguages(locale)]).then(() => {
          // After loading the locale data, inform changeListeners
          for (const [appName, listener] of this.localeChangeListeners) {
            logger.info("Informing " + appName + " that translations are loaded");
            listener(locale);
          }
        });
      }
    } else {
      const separatorIndex = locale.lastIndexOf("-");
      if (separatorIndex > 0) {
        // Try more general versions of the locale string, E.g. when 'en-US' (American English) is not supported, try 'en' (plain English)
        this.setLocale(locale.slice(0, separatorIndex));
      } else if (locale !== fallbackLocale && fallbackLocale.indexOf("-") < 0) {
        this.setLocale(fallbackLocale);
      }
    }
  }

  async loadBundle(locale: string) {
    const url = `/localizations/${locale}.json`;
    await axios.get(url).then(response => {
      const localizations = response.data || {};
      const intialData = {
        currentLocale: locale,
        locales: {
          [locale]: localizations
        }
      };
      intl.init(intialData);
      logger.info(`loaded ${Object.keys(localizations).length} translations for locale ${locale}`);
    });
  }

  async loadLanguages(locale: string) {
    const url = `/localizations/iso639-1-languages.${locale}.json`;
    await axios.get<string[][]>(url).then(response => {
      this.languages = new Map<string, string>();
      if (response.data) {
        for (const language of response.data) {
          this.languages.set(language[0], language[1]);
        }
      }
      logger.info(`loaded ${this.languages.size} language description for locale ${locale}`);
    });
  }

  public resolve(key: string, options?: any) {
    return intl.get(key, options).defaultMessage(key);
  }

  /**
   * Get the ISO 639-1 language codes with their localized description. The returned
   * value is an Object with a property for each language code and a value with the
   * localized description.
   */
  getLanguageDescriptions(): Map<string, string> {
    return new Map([...this.languages]);
  }

  getLanguageDescription(isoCode: string) {
    return this.languages.get(isoCode);
  }

  public getCurrentLocale(): string {
    if (this.currentLocale) {
      return this.currentLocale;
    }
    return getBrowserLocale();
  }

  registerLocaleChangeListener(id: string, listener: Function) {
    if (typeof id === "string" && typeof listener === "function") {
      if (this.localeChangeListeners.has(id)) {
        return;
      }
      this.localeChangeListeners.set(id, listener);
    }
  }

  unregisterLocaleChangeListener(id: string) {
    if (typeof id === "string") {
      this.localeChangeListeners.delete(id);
    }
  }
}

export function getBrowserLocale() {
  let uiLocale = localStorage.getItem(UI_LOCALE_KEY);
  if (uiLocale) {
    logger.info("uiLocale from local storage: " + uiLocale);
  } else {
    if (navigator.languages && navigator.languages.length) {
      logger.info("Available browser locales: " + navigator.languages.join(", "));
      uiLocale = navigator.languages[0];
    } else {
      logger.info("Available browser locale: " + navigator.language);
      uiLocale = navigator.language;
    }
    if (uiLocale) {
      logger.info("Matching browser locale: " + uiLocale);
    } else {
      logger.info("Falling back to uiLocale: " + fallbackLocale);
      uiLocale = fallbackLocale;
    }
  }
  return uiLocale;
}

export const localizer = new DefaultLocalizationService(getBrowserLocale());
