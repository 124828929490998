import React from "react";
import { localizer } from "../../services/LocalizationService";

export function NotFound() {
  return (
    <div>
      <h1>{localizer.resolve("NotFound")}</h1>
    </div>
  );
}
