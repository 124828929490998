import { Button, Form, Input, message } from "antd";
import { LoginOutlined } from "@ant-design/icons";
import { localizer, calculatePasswordHash } from "di-common";
import Axios from "axios";

function Login({ dispatch }) {
  const [form] = Form.useForm();

  function sendLogin({ username, password }) {
    const passwordHash = calculatePasswordHash(username, password);
    Axios.post("/api/login", {
      username,
      passwordHash
    }).then(
      response => {
        const user = response.data;
        dispatch({ type: "login", data: user });
      },
      error => {
        if (error.response.status === 401) {
          message.error(localizer.resolve("global.feedback.wrongCredentials"));
        }
        console.log(`${error}: ${error.response.data}`);
      }
    );
  }

  return (
    <Form form={form} layout="inline" onFinish={sendLogin}>
      <div className="login">
        <Form.Item
          name="username"
          rules={[
            {
              required: true,
              message: localizer.resolve("login.required.username")
            }
          ]}
        >
          <Input placeholder={localizer.resolve("login.username")} />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: localizer.resolve("login.required.password")
            }
          ]}
        >
          <Input.Password placeholder={localizer.resolve("login.password")} />
        </Form.Item>
        <Button
          type="default"
          htmlType="submit"
          icon={<LoginOutlined />}
          onClick={form.submit}
        />
      </div>
    </Form>
  );
}

export default Login;
